span.headText1 {
  color: var(--Primary-Blue, #003049);
  font-family: Poppins;
  font-size: 37.796px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.756px;
}

.containerHead {
  padding-left: 10px;
}

.wrapperHead {
  display: flex;
  padding-left: max(7%, 145px);
  padding-top: max(1%, 20px);
}
.form>.containerHead {
  display: inline-block;
  width: 32%; /* of wrapper width */
  margin: auto;
  text-align: left;
}

@media (max-width: 822px) {
  .wrapperHead {
    display: flex;
    padding-top: max(2%, 20px);
    padding-left: 0px;
    justify-content: center;
  }
   a:-webkit-any-link {
      color: var(--Primary-Blue, #003049);
   }
}

@media (max-width: 820px) and (min-height: 1024px) {
  .wrapperHead {
      padding-top: max(12%, 20px);
  }
}

@media (min-width: 2300px) {
  .wrapperHead {
    padding-left: max(1%, 145px);
    padding-top: max(3%, 20px);
  }
}
