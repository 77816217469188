.react-international-phone-input-container {
	display: inline-flex;
	width: 100px;
}

.react-international-phone-input {
	width: 60px;
}

#phone-input {
	vertical-align: top;
	margin-left: 10px;
	margin-top: 9px;
	width: 230px;
	padding-left: 0px;
	outline: none;
	text-align: left;
}

::placeholder {
	color: #8d939c;
	opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
	/* Edge 12-18 */
	color: #8d939c;
}

.input {
	display: flex;
	width: 319px;
	align-items: center;
	margin-top: 6px;
	margin-left: 2px;
}

.rapBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 427px; */
}

.messages {
	display: flex;
	justify-content: center;
}

.input.input {
	outline: none;
	border: none;
	padding: 13px 16px;
	color: #003049;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 100% */
}

.rapBorderBox {
	/* top | right | bottom | left */
	padding: 80px 602px 327px 602px;
}

.rapInputs {
	backdrop-filter: blur(2px);
	max-height: 538px;
	width: 356px;
	padding: 45px;
	border-radius: 19px;
	background: rgba(255, 255, 255, 0.7);
	gap: 20px;
}

span.signUp,
span.signIn,
span.signUp > a,
span.signIn > a {
	color: #0360f0;
	font-family: Poppins;
	font-size: 17px;
	font-style: normal;
	line-height: 20px; /* 117.647% */
}

a:link,
a:-webkit-any-link {
	text-decoration: none;
	color: var(--Primary-Blue, #003049);
}

.rapSpanSign {
	display: flex;
	height: 66px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.rapSpanSignIn,
.rapSpanSignUp {
	padding: 18px 32px;
	border-bottom: 1px solid #8f9ca3;
}

.headSign {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #0360f0;
	text-align: center;
	font-family: Poppins;
	font-size: 35px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.7px;
}

#submitBtn {
	margin-top: 52px;
	width: 359px;
}

.rapForm {
	padding-right: max(13%, 145px);
	margin-top: max(1%);
}

.wrapFormText {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 450px) {
	.input {
		width: 197px;
	}
}

label.label {
	display: block;
	padding-top: 20px;
	color: #8f9ca3;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

input.input.mobile {
	width: 288px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.wrapPageMobile {
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
}

.wrapFormTextMobile {
	width: 260px;
}

.wrapperBodyText {
	background: linear-gradient(73deg, #180936 37.28%, #0360f0 70.75%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	padding-left: max(7%, 145px);
	margin-top: max(0%, 115px);
	width: 670px;
}

.bodyText {
	font-family: Poppins;
	font-size: 46.222px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.924px;
}

.bodyTextSignIn,
.bodyTextSignUp {
	font-family: Poppins;
	font-size: 40px;
	max-width: 450px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.924px;
	background: linear-gradient(73deg, #180936 37.28%, #0360f0 70.75%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.bodyTextSignUp {
	max-width: 400px;
}

.react-international-phone-input-container {
	padding-top: 10px;
	padding-left: 5px;
}

input.react-international-phone-input {
	border: 1px solid white !important;
	color: #8d939c !important;
	font-size: 19px !important;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.21px;
}

.react-international-phone-input-container
	.react-international-phone-country-selector-button {
	padding: 25px 0px 28px 0px;
	margin: -8px 0px 0px -3px;
}

@media (max-width: 822px) {
	.rapForm {
		padding-right: 0px;
		margin-top: 20px;
		width: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.wrapPage {
		display: none;
	}
	label.label {
		padding-top: 2px;
	}
	#submitBtn {
		margin-top: 12px;
		width: 321px;
	}
	.wrapperBodyText {
		width: auto;
		padding-left: 0px;
		padding-top: 0%;
		width: auto;
	}
	.bodyTextSignIn,
	.bodyTextSignUp {
		font-size: 28px;
		text-align: center;
	}
	.wrapFormTextMobile {
		margin-top: -100px;
	}
	#root
		> div
		> div.wrapPageMobile
		> div.rapForm
		> form
		> div
		> svg:nth-child(8)
		> foreignObject
		> div
		> input {
		box-sizing: content-box;
		width: 2.7em;
		pointer-events: none;
		cursor: not-allowed;
	}
}

@media (max-width: 820px) and (min-height: 1024px) {
	.bodyTextSignIn,
	.bodyTextSignUp {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.custom-modal {
	position: relative;
	z-index: 999;
}
.custom-modal .content {
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 0;
}
.custom-modal .content .type {
	font-size: 18px;
	color: #999;
}
.custom-modal .content .message-type {
	font-size: 24px;
	color: #000;
}
.custom-modal .border-bottom {
	position: absolute;
	z-index: 9999;
}
.custom-modal .icon-top {
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	top: -60px;
	margin: 0 125px;
	font-size: 30px;
	color: #fff;
	line-height: 100px;
	text-align: center;
}

.page-wrapper {
	height: 100vh;
	background-color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 80px 0;
}
body {
	margin: 0;
	font-family: "Roboto";
}
@media only screen and (max-width: 800px) {
	.page-wrapper {
		flex-direction: column;
	}
}

.react-international-phone-input-container .react-international-phone-input,
input.react-international-phone-input {
	font-family: Roboto Flex !important;
}

#root
	> div
	> div.wrapPage
	> div.wrapFormText
	> div.rapForm
	> form
	> div
	> svg:nth-child(8)
	> foreignObject
	> div
	> input {
	box-sizing: content-box;
	width: 2.8em;
	pointer-events: none;
	cursor: not-allowed;
}

.messageSuccess,
.messageError {
	display: flex;
	justify-content: center;
	font-family: Poppins;
	font-size: 1.5em;
}

.errorBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	width: 465px;
	height: 412px;
	flex-shrink: 0;
	background: rgba(255, 255, 255, 0.7);
	border-radius: 19px;
	background: var(--White, #fff);
	box-shadow: 0px 0px 20px 0px rgba(76, 130, 211, 0.2);
	position: absolute;
	top: 0;
	bottom: 40px;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 999;
}

.errorBox.mobile {
	width: auto;
	height: 450px;
}

.error-icon {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 20%;
}

span.react-international-phone-country-selector-dropdown__list-item-country-name,
span.react-international-phone-country-selector-dropdown__list-item-dial-code {
	font-family: "Poppins";
	font-size: 12px;
}

ul.react-international-phone-country-selector-dropdown {
	position: fixed;
	width: 100%;
	outline: none;
}

@media (min-width: 822px) and (max-width: 1366px) {
	.rapForm {
		padding-right: max(1%, 65px);
		margin-top: auto;
	}
}

@media (max-width: 375px) {
	.bodyTextSignIn,
	.bodyTextSignUp {
		font-size: 17px;
	}
	.headSign {
		font-size: 25px;
	}
}

@media (min-width: 2300px) {
	.wrapperBodyText {
		padding-left: max(21%, 130px);
	}
	.wrapperBodyText,
	.rapForm {
		padding-right: max(21%, 130px);
	}
}

@media (min-width: 2800px) {
	.wrapperHead {
		padding-left: max(24%, 130px);
		padding-top: max(3%, 20px);
	}
	.wrapperBodyText,
	.rapForm {
		padding-right: max(24%, 130px);
	}
}

@media (max-width: 750px) {
	.errorBox {
		max-width: 370px;
	}
}

.errorval {
	display: none;
	padding-top: 10px;
	color: #f93232;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
