.iv-bottom {
    display: flex;
    padding-left: 33px;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 23px;
    /* left: 33px; */
    padding-left: max(1%, 133px);
    /* margin-bottom: max(1%, 20px); */
}

.ivBottomText {
    color: var(--Primary-Blue, #003049);
    font-family: Poppins;
    font-size: 15.669px;
    font-style: normal;
    font-weight: 300;
    line-height: 8.683px; /* 55.414% */
    margin-bottom: 2px;
}

@media (max-width: 768px) {
    .iv-bottom {
        padding-left: 0px;
        bottom: 10px;
        margin: 0 auto;
        width: 100%;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 375px) {
    .iv-bottom {
        bottom: -17px;
        left: 23px;
    }
}