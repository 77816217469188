.rapBtnLog {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
    background-color: white;
    cursor: pointer;
    right: 30px;
    top: 10px;
}

p#logoutBtn {
  padding-left: 10px;
  color: var(--Primary-Blue, #003049);
  font-family: Poppins;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.myBankImgMobile {
  display: none;
}

.myBankImg {
    background-color: white;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.rapImage {
    height: -webkit-fill-available;
    background-color: white;
}

@media (max-width: 822px) {
  .rapImage {
    padding-top: 0px;
  }
  .rapBtnLog {
    background-color: #ECF4FF;
    padding-right: 20px;
    right: 0px;
  }
  .myBankImg {
    display: none;
  }
  .myBankImgMobile {
    display: block;
  }
  .myBankImgMobile, .myBankImg {
    width: -webkit-fill-available;
  }
}


