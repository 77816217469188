.otp {
	display: flex;
    position: relative;
    margin: 0 auto;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.otp fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}

.otp fieldset div {
	display: flex;
	align-items: center;
	justify-content: center
}

.otp legend {
	margin: 0 auto 1em;
	color: #fff;
	font-weight: bold;
}

input[type="tel"] {
	width: .86em;
	line-height: 1;
	margin: .1em;
	padding: 8px 0 4px;
	font-size: 2.65em;
	text-align: center;
	appearance: textfield;
	-webkit-appearance: textfield;
	border: 0;
	color: #073A39;
    border-radius: 7.498px;
    border: 1.406px solid #B6B6B8;
}

input[type="tel"]:focus-visible {
    outline: none;
    border: 1.406px solid #0360F0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 2 groups of 3 items */
input[type="tel"]:nth-child(n+4) {
	order: 2;
}
.otp div::before {
	content: '';
	height: 2px;
	width: 12px;
	margin: 0 .25em;
	order: 1;
	background: #fff;
	border-radius: 2px;
	opacity: .4;
}

.otp label {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.verifyRap {
    display: flex;
    padding: 45px;
    border-radius: 19px;
    background: rgba(255, 255, 255, 0.70);
    gap: 20px;
    width: 452px;
    height: 411px;
    flex-shrink: 0;

    position: absolute;
    top:0;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
}

.btn {
    display: flex;
    width: 359px;
    height: 62px;
    padding: 14px 109px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #0360F0;
}

.bodyText {
    color: var(--Primary-Blue, #003049);
    text-align: center;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.headText {
    color: var(--Primary-Blue, #003049);
    text-align: center;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width: 768px) {
.verifyRap {
        width: 350px;
        height: 381px; 
        padding: 0px; 
    }
}

.verifySuccessBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 465px;
    height: 342px;
    flex-shrink: 0;
    background: rgba(255, 255, 255, 0.70);
    border-radius: 19px;
    background: var(--White, #FFF);
    box-shadow: 0px 0px 20px 0px rgba(76, 130, 211, 0.20);
    position: absolute;
    top: 0;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
}

.verifyText {
    color: var(--Primary-Blue, #003049);
    text-align: center;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 359px;
    height: 18px;
    flex-shrink: 0;
    padding-top: 30px;
}

button#closeBtn {
    width: 269px;
    height: 62px;
    padding: 14px 109px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 70px;
}

input {
    caret-color: #0360F0;
}
